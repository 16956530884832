<template>
  <div>
    <Breadcrumbs title="ПРОФИЛЬ" main="ПОЛЬЗОВАТЕЛЬ" />
    <div class="alert-profile" :class="{'error': error }" >
      {{ errorText }}
    </div>
    <div class="container-fluid">
<!--       <ChangeCover />-->
      <div class="wrapper-cover d-flex justify-content-center align-items-center">
        <img
            v-if="!imageCover && !isLoadingCover"
            class="wrapper-cover-image"
            src="@/assets/images/banner/background.png"
            alt="BackgroundImage"
            style="width: 100%; height: 200px"
        />
        <img
            v-else-if="!isLoadingCover"
            ref="image_cover"
            :class="{ 'adapt': !adapt }"
            class="wrapper-cover-image"
            :src="FRONT_URL + this.imageCover"
            alt="BackgroundImage"
            @load="setImageStyles"
        />
        <div v-if="isLoadingCover" class="wrapper-loader d-flex justify-content-center align-items-center">
          <div class="loader-cover"></div>
        </div>
        <div class="btn-cover d-flex justify-content-center align-items-center">
          <div>
<!--            <label for="input-file" class="m-0">-->
<!--              <vue-feather type="edit-2" class="edit"></vue-feather>-->
<!--              Изменить обложку-->
<!--            </label>-->
<!--            <input class="input-file-cover d-none" id='input-file' type="file" @change="handleFileCover">-->
          </div>
        </div>
      </div>
      <div class="user-profile">
        <div class="row">
          <tourProfile
            :fio="fio"
            :firstName="firstName"
            :rank="rank"
            :telegram="telegram"
            :email="email"
            :dateRegister="dateRegister"
            :imageAvatar="imageAvatar"
            :isLoadingAvatar="isLoadingAvatar"
            :handleFileAvatar="handleFileAvatar"
          />
        </div>
      </div>
      <div class="wrapper-profile">
        <div class="row">
          <div v-if="userId !== IND_USER_ID" class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-3">
            <div class="card">
              <div class="title-account d-flex justify-content-between align-items-center w-100 pt-4 pb-3 px-4">
                <h5 class="text-center">ВАШ НАСТАВНИК</h5>
<!--                <vue-feather-->
<!--                      @mouseover="showPopover=true" @mouseleave="showPopover=false"-->
<!--                      class="status_toggle middle sidebar-toggle"-->
<!--                      type="help-circle"-->
<!--                  ></vue-feather>-->
<!--                  <div v-show="showPopover" class="popover">-->
<!--                    Кнопка "Связаться" будет доступна в следующих обновлениях-->
<!--                  </div>-->
              </div>
              <div
                class="wrapper-parents w-100 p-4 d-flex justify-content-between align-items-center"
              >
                <div class="account d-flex justify-content-center align-items-center">
                  <div
                    class="avatar d-flex justify-content-center align-items-center text-white"
                  >
                    <span class="avatar-text">
                      {{ parent && parent.profile && parent.profile.fio ? parent.profile.fio.charAt(0) : '?' }}
                    </span>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="account-name mx-3"> {{ parent.profile ? parent.profile.fio : 'Профиль не найден' }}</div>
                    <div class="wrapper-world d-flex">
                      <div class="ttl-info text-start ttl-xs-mt mx-3 w-50">
                        <h6><i class="fa fa-phone"></i> Telegram</h6>
                        <span>
                          {{ parent && parent.profile && parent.profile.telegram ? parent.profile.telegram : "отсутствует" }}
                        </span>
                      </div>
                      <div class="ttl-info text-start ttl-sm-mb-0 mx-3 w-50">
                        <div class="ttl-info text-start">
                          <h6><i class="fa fa-envelope"></i> Email</h6><span style="white-space: nowrap">
                            {{ parent.profile ? parent.email : "отсутствует" }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btn-wrapper">
                  <button class="btn btn-light" @click="openChat(this.parent.id)">Связаться</button>
                </div>
              </div>
              <div
                class="wrapper-parents-android w-100 p-4 d-flex justify-content-between align-items-center"
              >
                <div class="account d-flex flex-column justify-content-center align-items-center">
                  <div
                    class="avatar d-flex justify-content-center align-items-center text-white"
                  >
                    <span class="avatar-text">
                      {{ parent && parent.profile && parent.profile.fio ? parent.profile.fio.charAt(0) : '?' }}
                    </span>

                  </div>
                  <div class="d-flex flex-column">
                    <div class="account-name mx-3 text-center my-2"> {{ parent.profile ? parent.profile.fio : 'Профиль не найден' }}</div>
                    <div class="wrapper-world d-flex flex-column my-3 w-100">
                      <div class="ttl-info text-start ttl-xs-mt mx-3">
                        <h6><i class="fa fa-phone"></i> Telegram</h6>
                        <span>
                          {{ parent && parent.profile && parent.profile.telegram ? parent.profile.telegram : "отсутствует" }}
                        </span>
                      </div>
                      <div class="ttl-info text-start ttl-sm-mb-0 mx-3 w-100 mt-3">
                        <div class="ttl-info text-start">
                          <h6><i class="fa fa-envelope"></i> Email</h6><span>
                            {{ parent.profile ? parent.email : "отсутствует" }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btn-wrapper d-flex justify-content-center align-items-center my-3">
                  <button class="btn btn-light">Связаться</button>
                </div>
              </div>
            </div>
          </div>
          <div ref="card4" class="col-12 col-md-6 col-xl-6 col-xxl-3 mt-3 opacity">
            <GrandCoupon v-if="userId !== IND_USER_ID" :email="email" />
            <VerificationCard
              v-else
              :btn="btn"
              :statusVerification="statusVerification"
              :idappeall="idappeall"
              :commentVerification="commentVerification"
            />
          </div>
          <div class="col-12 col-md-6 col-xl-6 col-xxl-4 mt-3 d-block d-xxl-none">
            <Refpath v-if="userId !== IND_USER_ID"/>
          </div>
          <div class="col-12 col-md-12 col-xxl-5 mt-3">
            <Capital :userId="userId" />
          </div>
          <div class="col-12 col-md-12 col-xxl-4 mt-3 d-none d-xxl-block" >
            <Refpath v-if="userId !== IND_USER_ID"/>
          </div>
          <div ref="card6" class="my-active-wrapper col-12 col-md-6 col-xl-6 col-xxl-4 mt-4">
            <Suspense suspensible>
              <Active :statisticsActivites="statisticsActivites"/>
              <template #fallback>
                Загрузка...
              </template>
            </Suspense>
          </div>
          <div class="col-12 col-md-6 col-xl-6 col-xxl-4 mt-4" v-if="userId !== IND_USER_ID">
            <Balance />
          </div>
          <div class="col-12 col-md-6 col-xl-6 col-xxl-4 mt-4" v-if="userId !== IND_USER_ID">
            <PlatformAccess />
            <PersonalActivity :accountActivityValueEndDate="accountActivityValueEndDate"/>
          </div>
          <div class="col-12 col-md-6 col-xl-6 col-xxl-4 mt-4 d-block d-xxl-none">
            <OrderthismonthCard />
          </div>
          <div ref="card5" class="col-12 col-md-12 col-xl-12 col-xxl-8 mt-4" v-if="userId !== IND_USER_ID">
            <CertificateValue />
          </div>
          <div class="col-12 col-md-6 col-xl-6 col-xxl-4 mt-4 d-none d-xxl-block">
            <OrderthismonthCard />
          </div>
          <div ref="card1" class="col-12 col-md-12 col-xl-12 col-xxl-8 mt-4 opacity" >
            <Events />
          </div>
          <div v-if="meeting" class="col-12 col-md-6 col-xl-6 col-xxl-4 mt-3 opacity" >
            <div v-if="userId !== IND_USER_ID" class="card card-body img-bg w-100 h-100 overflow-hidden">
              <div class="img-project h-70 d-flex justify-content-center">
                <div class="w-100 h-100 overflow-hidden">
                  <img
                      class="img-project-img w-100"
                      :src="this.FRONT_URL + meeting.titleImagePath"
                      alt=""
                  />
                </div>
              </div>
              <div class="pos">
                <div class="title text-center mt-3">
                  <b> Актуальная информация о ближайших событиях </b>
                </div>
                <div class="button-p text-center mt-3">
                  <router-link :to="'/events/list/' + meeting.id" class="btn btn-primary">Подробнее</router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-12 col-md-12 col-xl-6 col-xxl-4 mt-4 opacity" >
            <EventLook />
          </div>
          <div ref="card2" class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4 opacity">
            <Ticket />
          </div>
          <div ref="card3" class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4 opacity">
            <TopProjects :topProjects="topProjects" />
          </div>
        </div>

<!--        <div class="row mt-5">-->
<!--          <div class="col-12 col-md-6 .col-xl-6 col-xxl-4">-->
<!--            <OrderthismonthCard />-->
<!--          </div>-->
<!--          <div class="col-12 col-md-6 .col-xl-6 col-xxl-4">-->
<!--            <Balance />-->
<!--          </div>-->
<!--          <div class="col-12 col-md-6 .col-xl-6 col-xxl-4">-->
<!--            <div class="mb-4">-->
<!--              <MonthlyIncome />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="row">
<!--          <div class="col-12 col-md-12 col-xl-12 col-xxl-8 mt-3">-->
<!--            <div class="card card-body w-100">-->
<!--              <div class="card-header card-no-border text-left mt-5">-->
<!--                <h5>Будущее в ваших руках</h5>-->
<!--              </div>-->
<!--              <div class="card-body pt-0">-->
<!--                <p>-->
<!--                  Информация о самых перспективных проектах-->
<!--                  <br />-->
<!--                  и возможности инвестирования-->
<!--                  <br />-->
<!--                  в инновационные решения.-->
<!--                </p>-->
<!--              </div>-->
<!--              <div class="wrapper-btn my-5">-->
<!--                <router-link-->
<!--                  to="/vote"-->
<!--                  class="btn btn-primary pointer-cursor mt-2"-->
<!--                  >Голосовать</router-link-->
<!--                >-->
<!--              </div>-->
<!--              <div class="img">-->
<!--                <img src="../../../assets/images/team.png" alt="" />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div v-if="meeting" class="col-12 col-md-6 col-xl-6 col-xxl-4 mt-3">-->
<!--            <div class="card card-body img-bg w-100 h-100 overflow-hidden">-->
<!--              <div class="img-project h-70 d-flex justify-content-center">-->
<!--                <div class="w-100 h-100 overflow-hidden">-->
<!--                  <img-->
<!--                  class="img-project-img "-->
<!--                  :src="this.FRONT_URL + meeting.titleImagePath"-->
<!--                  alt=""-->
<!--                />-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="pos">-->
<!--                <div class="title text-center mt-3">-->
<!--                  <b> Актуальная информация о ближайших событиях </b>-->
<!--                </div>-->
<!--                <div class="button-p text-center mt-3">-->
<!--                  <router-link :to="'/events/list/' + meeting.id" class="btn btn-primary">Подробнее</router-link>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="col-12 col-md-12 col-xl-12 col-xxl-8 mt-3">
          <!-- <div class="card card-body w-100">
            <div class="card-header card-no-border text-left mt-5">
              <h5>Инструменты для бизнеса</h5>
            </div>
            <div class="card-body pt-0">
              <div class="embed-responsive embed-responsive-16by9">
                <video class="p-3" controls controlsList="nodownload" style="height: 100%; width: 100%;"
                       preload="none" poster="../../../assets/images/video/de7efef480b19794da214f40b0f7f359.png">
                  <source src="../../../assets/video/video.mp4" data-fluid-hd=""
                  />
                </video>
              </div>
            </div>
            <div class="wrapper-btn wrapper-btn-video my-5">
              <a
                  href="../../../../my/files/profile/listovka.pdf"
                  target="_blank"
                  class="btn btn-primary pointer-cursor mt-2"
              >Скачать листовку</a>
              <a
                  href="../../../../my/files/profile/Презентация.pptx"
                  target="_blank"
                  class="btn btn-primary pointer-cursor mt-2"
              >Скачать Презентацию</a>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserDataService from "@/services/UserDataService";
import CertificateDataService from "@/services/CertificateDataService"

import axios from "axios";
import tourProfile from "./tourProfile.vue";
import Ticket from "@/pages/users/profile/Ticket.vue";
import VerificationCard from "./widget/VerificationCard.vue";
import PlatformAccess from "./widget/PlatformAccess.vue";
import Capital from "./widget/Capital.vue";
import OrderthismonthCard from "./widget/OrderthismonthCard/OrderthismonthCard.vue";
import Balance from "./widget/OrderthismonthCard/Balance.vue";
import MonthlyIncome from "./widget/OrderthismonthCard/MonthlyIncome.vue";
import PersonalActivity from "./widget/OrderthismonthCard/PersonalActivity.vue";
import CertificateValue from "./widget/OrderthismonthCard/CertificateValue.vue";
import ChangeCover from "./ChangeCover.vue";
import { fetchData } from "@/data/comon";
import Refpath from "./widget/Refpath.vue";
import Active from "./widget/MyActive.vue";
import Events from "./widget/Events.vue";
import EventLook from "./widget/EventLook.vue";
import TopProjects from  "./TopProjects.vue";
import GrandCoupon from "./widget/GrandCoupon.vue"
// import personalActivity from './widget/PersonalActivity.vue'
import ChatsDataService from "@/services/ChatsDataService";
export default {
  name: "userProfile",
  data() {
    return {
      userId: 1,
      fio: "",
      firstName: "",
      rank: "",
      telegram: "",
      email: "",
      dateRegister: "",
      commentVerification: "",
      statusVerification: null,
      idappeall: null,
      btn: 1,
      meeting: [],
      parent: [],
      showPopover: false,
      imageCover: null,
      imageAvatar: null,
      isLoadingCover: false,
      isLoadingAvatar: false,
      error: false,
      dataUser: {
        respondingUserId: Number,
      },
      errorText: '',
      adapt: false,
      topProjects: [],
      statisticsActivites: [],
      statisticsActivitesPercent: 0,
      accountActivityValueEndDate: '',
      isVisible: {
        card1: false,
        card2: false,
        card3: false,
        card4: false,
        card5: false,
        card6: false,
      },
      animationPlayed: {
        card1: false,
        card2: false,
        card3: false,
        card4: false,
        card5: false,
        card6: false,
      },
    };
  },
  components: {
    tourProfile,
    Ticket,
    VerificationCard,
    PlatformAccess,
    Capital,
    OrderthismonthCard,
    Balance,
    MonthlyIncome,
    PersonalActivity,
    CertificateValue,
    ChangeCover,
    Refpath,
    Active,
    Events,
    EventLook,
    TopProjects,
    GrandCoupon
  },
  mounted() {
    this.getUser();
    this.getNearestMeeting();
    this.getStatisticsActivites();
    this.accountActivityEndDate();
    this.getTopProjects();

    fetchData();
    // this.fetchBackgroundImage()
  },
  methods: {

    initIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {

          if (entry.isIntersecting) {
            if (entry.target === this.$refs.card1) {
              this.isVisible.card1 = true;
              this.animationPlayed.card1 = true;
            } else if (entry.target === this.$refs.card2) {
              this.isVisible.card2 = true;
              this.animationPlayed.card2 = true;
            } else if (entry.target === this.$refs.card3) {
              this.isVisible.card3 = true;
              this.animationPlayed.card3 = true;
            } else if (entry.target === this.$refs.card4) {
              this.isVisible.card4 = true;
              this.animationPlayed.card4 = true;
            } else if (entry.target === this.$refs.card5) {
              this.isVisible.card5 = true;
              this.animationPlayed.card5 = true;
            } else if (entry.target === this.$refs.card6) {
              this.isVisible.card6 = true;
              this.animationPlayed.card6 = true;
            }
          } else {
            if (entry.target === this.$refs.card1) {
              this.isVisible.card1 = false;
            } else if (entry.target === this.$refs.card2) {
              this.isVisible.card2 = false;
            } else if (entry.target === this.$refs.card3) {
              this.isVisible.card3 = false;
            } else if (entry.target === this.$refs.card4) {
              this.isVisible.card4 = false;
            } else if (entry.target === this.$refs.card5) {
              this.isVisible.card5 = false;
            } else if (entry.target === this.$refs.card6) {
              this.isVisible.card6 = false;
            }
          }
        });
      }, options);

      this.observer.observe(this.$refs.card1);
      this.observer.observe(this.$refs.card2);
      this.observer.observe(this.$refs.card3);
      this.observer.observe(this.$refs.card4);
      this.observer.observe(this.$refs.card5);
      this.observer.observe(this.$refs.card6);
    },

    async getStatisticsActivites() {
      await CertificateDataService.getStatisticsActivites()
          .then((response) => {
            this.statisticsActivites = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
    },

    async accountActivityEndDate() {
      await CertificateDataService.accountActivityEndDate()
          .then((response) => {
            this.accountActivityValueEndDate = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
    },

    async getTopProjects() {
      await UserDataService.getTopProjects()
          .then((response) => {
            this.topProjects = response.data;
          })
    },
    openChat(respondingUser){
      this.dataUser = {
        respondingUserId : respondingUser
      }
      ChatsDataService.postChats(this.dataUser)
          .then((response) => {
            const chatId = response.data.id;

            this.$router.push({ name: 'chatId', params: { chatId } });
          })
    },
    setImageStyles() {

      const image = this.$refs.image_cover;
      const width = image.naturalWidth;
      const height = image.naturalHeight;

      if (width > height) {
        this.adapt = true
      } else {
        this.adapt = false
      }

      image.onload = () => {
        const width = image.naturalWidth;
        const height = image.naturalHeight;

        console.log(width)
        console.log(height)

        if (width > height) {

          image.style.width = '100%';
        } else {

          image.style.height = '100%';
        }
      };
    },

    async getNearestMeeting() {
      await UserDataService.getNearestMeeting()
        .then((response) => {
          this.meeting = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getUser() {

      this.isLoadingCover = true

      await UserDataService.get()
        .then((response) => {
          const user = response.data;
          this.userId = user.user.id
          this.parent = user.user.parent;
          this.firstName = user.firstName;
          this.fio =
            user.lastName + " " + user.firstName + " " + user.middleName;
          this.rank = user.user.rank ? user.user.rank.name : "Ранг отсутствует";
          this.telegram = user.telegram ? user.telegram : "отсутствует";
          this.email = user.user.email;
          if (user.passportVerification) {
            this.commentVerification = user.passportVerification.comment;
            this.statusVerification = user.passportVerification.status.id;
            this.idappeall = user.passportVerification.id;
          }
          console.log(response.data)
          this.imageCover = user.backgroundImage;
          this.imageAvatar = user.largeAvatar;

          this.isLoadingCover = false

          this.dateRegister = new Date(user.created_at).toLocaleDateString(
            "ru-Ru"
          );

          this.setImageStyles()
        })
        .catch((e) => {

          this.isLoadingCover = false

        });
    },

    async handleFileCover(event) {
      const file = event.target.files[0]
      const formDataDetail = new FormData();
      formDataDetail.append("file", file)

      this.isLoadingCover = true

      await UserDataService.fileUploadsCover(formDataDetail)
          .then((response) => {
            this.isLoadingCover = false;
            this.getUser()
          })
          .catch((e) => {
            console.log(e.response.data.message)
            this.errorText = e.response.data.message
            this.error = true
            this.isLoadingCover = false;
            setTimeout(() => {
              this.error = false
            }, 5000)
            console.log(e)

          })
    },
    async handleFileAvatar(event) {
      const file = event.target.files[0]
      const formDataDetail = new FormData();
      formDataDetail.append("file", file)

      this.isLoadingAvatar = true

      await UserDataService.fileUploadsAvatar(formDataDetail)
          .then((response) => {
            console.log(response)
            this.isLoadingAvatar = false;
            this.getUser()
          })
          .catch((e) => {
            console.log(e.response.data.message)
            this.errorText = e.response.data.message
            this.error = true
            this.isLoadingAvatar = false;
            setTimeout(() => {
              this.error = false
            }, 5000)
            console.log(e)

          })
    }
  },
};
</script>
<style scoped>

.isVisible{
  opacity: 0;
}

.slide-enter-right {
  animation: slide-enter-right 0.8s ease-out;
}

.slide-enter-top {
  animation: slide-enter-top 0.8s ease-out;
}

.slide-enter-left {
  animation: slide-enter-left 0.8s ease-out;
}

@keyframes slide-enter-right {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-enter-top {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1 !important;
    transform: translateY(0);
  }
}

@keyframes slide-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.wrapper-parents-android{
  display: none !important;
}
.wrapper-parents{
  display: block;
}
.account-name{
  font-size: 20px;
  font-weight: 500;
}
.ttl-info{
  color: #59667a;
}
.ttl-info h6{
  font-size: 12px;
}
.avatar-text {
  font-size: 24px;
}
.title-account {
  border-bottom: 1px solid #dee2e6;
}
.avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: #449df3;
}
.wrapper-btn-video {
  display: flex;
  justify-content: space-around;
}
.wrapper-profile {
  max-width: 1400px;
  margin: 30px auto;
}
.card {
  overflow: hidden;
  position: relative;
}
.img {
  display: block;
  position: absolute;
  right: -50px;
  z-index: 1;
}
.wrapper-btn {
  margin-left: 28px;
}
.card-body,
.wrapper-btn {
  z-index: 2;
}
.img-project-img {
  border-radius: 5px;
  margin: 0 auto;
}
.card{
  position: relative;
}
.popover {
  position: absolute;
  top: 15px;
  right: 50px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 999;
  opacity: 1;
  transition: all .5s;
}
@media (max-width: 600px) {
  .wrapper-parents{
    display: none !important;
  }
  .wrapper-parents-android{
    display: block !important;
  }
}
@media (max-width: 1200px) {
  .img {
    display: none;
  }
}

</style>
