<template>
  <div class="row mt-5">
    <div class="col-xl-12 col-sm-12 box-col-12">
      <div class="card height-equal">
        <div class="card-header card-no-border">
          <div class="header-top ">

            <div class="drop-down-menu d-flex align-items-center">
              <h5>Cтатистика Сети</h5>
              <button class="drop-down-btn" @click="toggleDropdown">{{ year }} <img src="../../assets/images/network/Arrow.svg" alt=""></button>
              <div class="drop-down-content" v-if="isDropdownOpen">
                <a href="#" @click.prevent="selectYear(2024)">2024</a>
                <a href="#" @click.prevent="selectYear(2023)">2023</a>
              </div>
            </div>
            <div class="menu d-flex">
              <div class="menu-item">
                <div class="menu-circle-certificate"></div>
                Сертификаты
              </div>
              <div class="menu-item">
                <div class="menu-circle-course"></div>
                Курсы
              </div>
              <div class="menu-item">
                <div class="menu-circle-user"></div>
                Пользователи
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="income-wrapper d-flex">
            <div class="main-income-chart">
              <div id="income_chart" ref="chart"></div>
            </div>
            <div class="wrapper-income-btn">
              <div class="wrapper-income-title d-flex">
                <span class="text-muted title-item income-title">Наименование</span>
                <span class="text-muted title-item">Сумма</span>
                <span class="text-muted title-item">Изменение</span>
              </div>
              <div class="d-flex wrapper-item-income mt-4">
                <span class="text-muted income income-title">Сертификаты</span>
                <div class="income-count income">{{ certificates.points }} &#8381;</div>
                <div class="income-cent income">-</div>
              </div>
              <div class="d-flex wrapper-item-income mt-3">
                <span class="text-muted income  income-title">Курсы</span>
                <div class="income-count income">{{ course.points }} &#8381;</div>
                <div class="income-cent income">-</div>
              </div>
              <div class="d-flex wrapper-item-income mt-3">
                <span class="text-muted income income-title">Пользователи</span>
                <div class="income-count income">{{ users.countUsers }} Шт</div>
                <div class="income-cent income">-</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { defineComponent, onMounted, ref } from 'vue';
import ApexCharts from 'apexcharts';
import NetworksDataService from "@/services/NetworksDataService";
export default defineComponent({
  name: 'IncomeChart',
  data(){
    return {
      course: {},
      certificates: {},
      users: {},
      year: 2024,
      isDropdownOpen: false,
    }
  },
  mounted(){
    this.getCourses()
    this.getCertificates()
    this.getUsers()
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectYear(selectedYear) {
      this.year = selectedYear;
      this.isDropdownOpen = false;
      this.getCourses();
      this.getCertificates();
      this.getUsers();
      this.getAll();
    },
    getCourses(){
      NetworksDataService.getGraphicsCourses(this.year)
          .then(({ data }) => {
            this.course = data;
          })
          .catch(e => {
            console.log(e);
          })
    },

    getCertificates(){
      NetworksDataService.getGraphicsCertificates(this.year)
          .then(({ data }) => {
            this.certificates = data;
          })
          .catch(e => {
            console.log(e);
          })
    },

    getUsers(){
      NetworksDataService.getGraphicsUsers(this.year)
          .then(({ data }) => {
            this.users = data;
          })
          .catch(e => {
            console.log(e);
          })
    },

  },
  setup() {
    const chart = ref(null);
    const course = ref(null);
    const graphCertificate = ref(null);
    const users = ref(null);
    const year = ref(2024);
    const chartInstance = ref(null);

    const options_income = ref ({
      series: [
        {
          name: "Сертификаты",
          type: "line",
          data: [],
        },
        {
          name: "Пользователи",
          type: "line",
          data: [],
        },
        {
          name: "Курсы",
          type: "line",
          data: [],
        },
      ],
      chart: {
        height: 332,
        type: "line",
        toolbar: {
          show: false,
          tools: {
            zoom: false,
            selection: false,
          },
        },
        dropShadow: {
          enabled: true,
          top: 4,
          left: 0,
          blur: 2,
          colors: ["#7366FF", "#54BA4A", "#FFAA05"],
          opacity: 0.02,
        },
      },
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      colors: ["#7366FF", "#54BA4A", "#FFAA05"],
      stroke: {
        width: 3,
        curve: "smooth",
        opacity: 1,
      },
      markers: {
        discrete: [],
      },
      tooltip: {
        shared: true,
        intersect: false,
        marker: {
          width: 5,
          height: 5,
        },
      },
      xaxis: {
        type: "category",
        categories: ["Янв", "Фев", "Март", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
        tickAmount:12,
        crosshairs: {
          show: false,
        },
        labels: {
          style: {
            colors: "var(--chart-text-color)",
            fontSize: "12px",
            fontFamily: "Rubik, sans-serif",
            fontWeight: 400,
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 1,
          opacityFrom: 0.95,
          opacityTo: 1,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        min:0,
        tickAmount: 5,
        labels: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 1736,
          options: {
            chart: {
              height: 230,
              offsetX: 0,
            },
          },
        },
        {
          breakpoint: 1736,
          options: {
            chart: {
              height: 230,
              offsetX: 0,
            },
          },
        },


      ],
    });

    const getAll = () => {

      const promises = [];

      promises.push(
          NetworksDataService.getGraphicsCourses(year.value)
              .then(({ data }) => {
                course.value = data;
                const graphicData = [...course.value.graphic];
                options_income.value.series[0].data = graphicData;

              })
      );

      promises.push(
          NetworksDataService.getGraphicsCertificates(year.value)
              .then(({ data }) => {
                graphCertificate.value = data;
                const graphicData = [...graphCertificate.value.graphic];
                options_income.value.series[2].data = graphicData;

              })
      );

      promises.push(
          NetworksDataService.getGraphicsUsers(year.value)
              .then(({ data }) => {
                users.value = data;
                const graphicData = [...users.value.graphic];
                options_income.value.series[1].data = graphicData;

              })
      );

      Promise.all(promises)
          .then(() => {
            renderChart();
          })
          .catch(e => {
            console.log(e);
          });
    };
    const renderChart = () => {
      if (chartInstance.value) {
        chartInstance.value.destroy();
      }
      chartInstance.value = new ApexCharts(chart.value, options_income.value);
      chartInstance.value.render();
    };
    onMounted(() => {
      getAll();
    });

    return {
      chart,
      options_income,
      year,
      getAll,
    };
  },
});
</script>

<style scoped>
.drop-down-menu {
  position: relative;
  display: inline-block;
}
.drop-down-menu h5{
  margin-right: 103px;
}
.drop-down-btn {
  background-color: rgba(115, 102, 255, 0.12);
  color: rgba(115, 102, 255, 1);
  padding: 6px 12px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.drop-down-content {
  display: none;
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.drop-down-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.drop-down-content a:hover {
  background-color: #f1f1f1;
}

.drop-down-content {
  display: block;
}
.row, #income_chart{
  user-select: none;
}
.main-income-chart, .wrapper-income-btn{
  width: 50%;
}
.income-wrapper{
  width: 100%
}
.menu-item {
  display: flex;
  align-items: center;
}

.menu-item .menu-circle-certificate {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #7366FF;
  margin-right: 10px;
}
.menu-circle-course{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 161, 50, 1);
  margin-right: 10px;
}
.menu-circle-user{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(84, 186, 74, 1);
  margin-right: 10px;
}
.title-item{
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(82, 82, 108, 0.75);
  font-weight: 500;
  font-size: 16px;
}
.wrapper-item-income{
  background: rgba(246, 247, 249, 1);
  padding: 24px 24px 29px 16px;
}
.income{
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.income-title{
  justify-content: left;
}
.menu{
  gap: 10px;
}
@media screen and (max-width: 1200px){
  .header-top{
    flex-direction: column;
  }
  .title-item{
    display:none;
  }
  .menu-item{
    font-size: 14px;
  }
  .menu-circle-certificate{
    width: 8px;
    height: 8px;
  }
  .menu{
    margin-top: 10px;
    gap: 10px;
  }
  .drop-down-menu h5{
    margin-right: 20px;
  }
  .income-wrapper{
    flex-direction: column;
  }
  .main-income-chart, .wrapper-income-btn{
    width: 100%;
  }
  .income{
    width: 60%;
  }
}
@media screen and (max-width: 430px){
  .menu-item{
    font-size: 12px;
  }
}
</style>