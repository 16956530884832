<template>
  <div>
    <div class="modal" v-if="isOpenModal" >
      <div class="modal-overlay" @click="closeModal"></div>
      <div class="modal-content" :class="{ 'slide-enter-top': isOpenModal}">
        <div class="close">
          <img src="../../assets/images/network/Close.svg" alt="Close" @click="closeModal">
        </div>
        <modal
            @close="closeModal"
            :fio="networkUserRecursive.profile?.fio || 'Не указано'"
            :email="networkUserRecursive.email || 'Не указано'"
            :phone="networkUserRecursive.profile?.phone || 'Не указано'"
            :rank="networkUserRecursive.rank && networkUserRecursive.rank.name ? networkUserRecursive.rank.name : 'Не указано'"
            :id="networkUserRecursive.id"
        />
        <!--              :backgroundImage="this.networkUser.profile.backgroundImage"-->
      </div>
    </div>
    <div
        v-for="(item, index) in children"
        :key="item.id"
    >
      <div :class="[currentIndex === item.id && isDropdown ? 'wrapper-item-accordion-gray level-' + level : '']"
           class="wrapper-item-accordion d-flex align-items-center pb-2">
      <div class="item-accordion item-accordion-one d-flex" @click="OpenModal(item.id, item.profileSettings[0].isEnable)">
        <div class="item-accordion-images d-flex">
          <div  class="avatar-circle" v-if="item.profile.mediumAvatar === null || item.profileSettings[0].isEnable">
            {{ getFirstLetter(item.profile.fio) }}
          </div>
          <img :src="this.FRONT_URL + item.profile.mediumAvatar" v-else-if="item.profile.mediumAvatar && !item.profileSettings[0].isEnable" class="avatar-circle" alt="">
          <img v-if="item.rank === null" src="" class="item-accordion-images-two mobile" alt="">
          <img v-else-if="item.rank.id === 1" src="../../assets/images/network/lvlUser0.svg" class="item-accordion-images-two mobile" alt="">
          <img v-else-if="item.rank.id === 2" src="../../assets/images/network/lvlUser1.svg" class="item-accordion-images-two mobile" alt="">
          <img v-else-if="item.rank.id === 3" src="../../assets/images/network/lvlUser2.svg" class="item-accordion-images-two mobile" alt="">
          <img v-else-if="item.rank.id === 4" src="../../assets/images/network/lvlUser3.svg" class="item-accordion-images-two mobile" alt="">
          <img v-else-if="item.rank.id === 5" src="../../assets/images/network/lvlUser4.svg" class="item-accordion-images-two mobile" alt="">
          <img v-else-if="item.rank.id === 6" src="../../assets/images/network/lvlUser5.svg" class="item-accordion-images-two mobile" alt="">
        </div>
        <div class="item-accordion-description">
          <div :class="item.isActivate ? 'fio activateUser' : 'fio'" v-if="item.profile.fio">
            {{ item.profile.fio }}
          </div>
          <div class="fio" v-else-if="item.profile.firstName">
            {{ item.profile.firstName }}
          </div>
          <div class="email" v-if="item.profile.fio">
            {{ item.email }}
          </div>
          <div class="email" v-else-if="item.profile.firstName">
            Почта скрыта
          </div>
        </div>
      </div>
        <div class="item-accordion mobile" @click="OpenChildren(item.id)">
          <img src="../../assets/images/network/unlock.svg" alt="" v-if="item.profile.fio">
          <img src="../../assets/images/network/lock.svg" alt="" v-if="item.profile.firstName">
        </div>
        <div class="item-accordion item-accordion-count" @click="OpenChildren(item.id)">
          {{item.countChildren}}
        </div>
        <div  class="item-accordion-arrow d-flex" @click="OpenChildren(item.id)">
          <vue-feather class="arrow-down" type="chevron-down" :class="{ rotated: item.id === rotateIndex }"></vue-feather>
        </div>
      </div>
      <recursive
          v-if="isDropdown && currentIndex === item.id"
          :isDropdownOpen="isDropdown"
          :index="currentIndex"
          :children="currentChildren"
          @update:isDropdownOpen="isDropdown = $event"
          :networkUserRecursive="networkUserRecursive"
          :level="level + 1"
      ></recursive>

  </div>
  </div>
</template>

<script>
import modal from "@/pages/networks/modal.vue";
import networksDataService from "@/services/NetworksDataService";

export default {
  name: 'Recursive',
  components: {modal},
  props: {
    index: {
      type: Number,
      required: true
    },
    children: {
      type: Array,
      required: true
    },
    networkUserRecursive: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isDropdown: false,
      currentIndex: null,
      rotateIndex: null,
      isOpenModal: false,
      currentChildren: [],
      networkUserRecursive: [],
    }
  },
  methods: {
    isGray(level) {
      return level % 2 === 0;
    },
    OpenModal(id, enabled) {
      if (enabled === false) {
        networksDataService.getById(id).then(response => {
          this.networkUserRecursive = response.data;
          if (this.networkUserRecursive){
            this.isOpenModal = true
          }
        })
      }
    },
    closeModal() {
      this.isOpenModal = false;
    },
    getFirstLetter(fullName) {
      return fullName ? fullName.charAt(0).toUpperCase() : '';
    },
    OpenChildren(id) {
      if (this.currentIndex === id) {
        this.currentIndex = null;
        this.isDropdown = false;
        this.rotateIndex = null;
      } else {
        this.currentIndex = id;
        this.rotateIndex = id;
        this.currentChildren = this.children.find(item => item.id === id).children;
        this.isDropdown = !this.isDropdown;
      }
    },
  }
}
</script>

<style scoped>
.wrapper-item-accordion-gray{
  background-color: #DEE2E675;
}
.wrapper-item-accordion-gray.level-1 {
  background-color: #DEE2E695;
}

.wrapper-item-accordion-gray.level-2 {
  background-color: rgba(196, 198, 203, 0.31);
}
.wrapper-item-accordion-gray.level-3 {
  background-color: rgba(184, 186, 190, 0.31);
}
.wrapper-item-accordion-gray.level-4{
  background-color: rgba(184, 186, 190, 0.31);
}
.wrapper-item-accordion-gray.level-5 {
  background-color: rgba(172, 174, 178, 0.31);
}
.wrapper-item-accordion-gray.level-6 {
  background-color: rgba(160, 164, 170, 0.31);
}
.wrapper-item-accordion-gray.level-7 {
  background-color: rgba(150, 154, 160, 0.31);
}
.wrapper-item-accordion-gray.level-8 {
  background-color: rgba(140, 144, 150, 0.31);
}
.wrapper-item-accordion-gray.level-9{
  background-color: rgba(130, 134, 140, 0.31);
}
.wrapper-item-accordion-gray.level-10 {
  background-color: rgba(120, 124, 130, 0.31);
}
.wrapper-item-accordion-gray.level-11 {
  background-color: rgba(110, 114, 120, 0.31);
}
.wrapper-item-accordion-gray.level-12 {
  background-color: rgba(100, 104, 110, 0.31);
}
.wrapper-item-accordion-gray.level-13 {
  background-color: rgba(90, 94, 100, 0.31);
}
.wrapper-item-accordion-gray.level-14 {
  background-color: rgba(80, 84, 90, 0.31);
}

.activateUser{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #54BA4A;
}

.close{
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}
.mobile{
  @media screen and (max-width: 1200px) {
    display: none;
  }
}
.avatar-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #7366FF;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.wrapper-item-accordion{
  padding: 16px;
  cursor: pointer;
  border-bottom: 1px solid rgba(222, 226, 230, 1)
}
.email{
  color: rgba(82, 82, 108, 0.75);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
.item-accordion-count{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.fio{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.item-accordion-description{
  margin-left: 8px;
}
.item-accordion-images-two{
  margin-left: 8px;
}
.item-accordion{
  width: 21%;
}
.item-accordion-one{
  width: 60%;
}
.arrow-down {
  transition: transform 0.3s ease;
}
.rotated {
  transform: rotate(90deg);
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.modal-content {
  margin: 20px;
  position: relative;
  background: white;
  border-radius: 15px;
  z-index: 1001;
  max-width: 498px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.slide-enter-top {
  animation: slide-enter-top 0.8s ease-out;
}
@keyframes slide-enter-top {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
code{
  padding: 0 !important;
  margin: 0 !important;
}
</style>