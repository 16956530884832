<template>
    <Breadcrumbs title="ПАРТНЁРЫ"/>
    <div class="container-fluid wrapper-parents">
      <div class="row">
        <div class="col-sm-12">
          <div v-if="partners.length > 0" v-for=" item of partners " class="card p-4 card-item">
            <div class="item-partner d-flex flex-column flex-md-row justify-content-between align-items-center my-4">
              <div class="image-rantie position-relative mb-4 mb-md-0 me-0 me-md-5">
                <img class="block" src="@/assets/images/partners/grand-coupon.jpg"/>
                <img :src="this.FRONT_URL + item.photo" alt=""/>
              </div>
              <div class="content-partner flex-grow-1 d-flex flex-column h-100">
                <p class="m-0 flex-grow-1">
                  <h5 class="pb-3">
                    Стратигический партнер Рантье
                  </h5>
                  {{ item.description }}
                </p>
                <div class="d-flex align-items-center py-3">
                  <a v-if="item.site" class="wrapper-parents-href d-flex cursor-pointer" :href="item.site" target="_blank">
                    <img src="@/assets/svg/network.svg" alt="">
                    <p class="ms-1">
                      Сайт
                    </p>
                  </a>
                  <div v-if="item.phone" class="wrapper-parents-href d-flex mx-2" target="_blank">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.50246 4.25722C9.19873 3.4979 8.46332 3 7.64551 3H4.89474C3.8483 3 3 3.8481 3 4.89453C3 13.7892 10.2108 21 19.1055 21C20.1519 21 21 20.1516 21 19.1052L21.0005 16.354C21.0005 15.5361 20.5027 14.8009 19.7434 14.4971L17.1069 13.4429C16.4249 13.1701 15.6483 13.2929 15.0839 13.7632L14.4035 14.3307C13.6089 14.9929 12.4396 14.9402 11.7082 14.2088L9.79222 12.2911C9.06079 11.5596 9.00673 10.3913 9.66895 9.59668L10.2363 8.9163C10.7066 8.35195 10.8305 7.57516 10.5577 6.89309L9.50246 4.25722Z" stroke="#52526C" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p class="ms-1">
                      {{ item.phone }}
                    </p>
                  </div>
                  <div v-if="item.city" class="wrapper-parents-href d-flex mx-2" target="_blank">
                    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 18H3M3 18H13M3 18V4.2002C3 3.08009 3 2.51962 3.21799 2.0918C3.40973 1.71547 3.71547 1.40973 4.0918 1.21799C4.51962 1 5.08009 1 6.2002 1H9.8002C10.9203 1 11.4796 1 11.9074 1.21799C12.2837 1.40973 12.5905 1.71547 12.7822 2.0918C13 2.5192 13 3.07899 13 4.19691V10M13 18H19M13 18V10M19 18H21M19 18V10C19 9.06812 18.9999 8.60241 18.8477 8.23486C18.6447 7.74481 18.2557 7.35523 17.7656 7.15224C17.3981 7 16.9316 7 15.9997 7C15.0679 7 14.6019 7 14.2344 7.15224C13.7443 7.35523 13.3552 7.74481 13.1522 8.23486C13 8.60241 13 9.06812 13 10M6 8H10M6 5H10" stroke="#52526C" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p class="ms-1">
                      {{ item.city }}
                    </p>
                  </div>

                  <a v-if="item.video" class="wrapper-parents-href d-flex mx-2 cursor-pointer" :href="item.site" target="_blank">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 11H16C17.8856 11 18.8284 11 19.4142 11.5858C20 12.1716 20 13.1144 20 15V16C20 18.8284 20 20.2426 19.1213 21.1213C18.2426 22 16.8284 22 14 22H10C7.17157 22 5.75736 22 4.87868 21.1213C4 20.2426 4 18.8284 4 16V11Z" stroke="#52526C" stroke-opacity="0.75" stroke-width="1.5"/>
                      <path d="M4.00275 10.9997C3.51895 9.19419 3.27706 8.29142 3.48511 7.51496C3.62141 7.00629 3.8892 6.54247 4.26157 6.1701C4.82997 5.60169 5.73274 5.35979 7.53828 4.876L14.5412 2.99956C15.2145 2.81917 15.5511 2.72897 15.8459 2.70965C17.0567 2.63029 18.1961 3.28811 18.7328 4.37636C18.8634 4.64136 18.9536 4.97798 19.134 5.65121C19.1942 5.87562 19.2242 5.98783 19.2307 6.08611C19.2571 6.48971 19.0379 6.8695 18.6751 7.04839C18.5868 7.09195 18.4746 7.12201 18.2502 7.18214L4.00275 10.9997Z" stroke="#52526C" stroke-opacity="0.75" stroke-width="1.5"/>
                      <path d="M14.7014 2.94148L14.0636 8.28874" stroke="#52526C" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round"/>
                      <path d="M8.42013 4.6238L7.78238 9.97106" stroke="#52526C" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round"/>
                      <path d="M14 16.5C14 16.0778 13.6028 15.793 12.8084 15.2235C12.0031 14.6462 11.6005 14.3575 11.3002 14.5695C11 14.7814 11 15.3543 11 16.5C11 17.6457 11 18.2186 11.3002 18.4305C11.6005 18.6425 12.0031 18.3538 12.8084 17.7765C13.6028 17.207 14 16.9222 14 16.5Z" stroke="#52526C" stroke-opacity="0.75" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                    <p class="ms-1">
                      Видео
                    </p>
                  </a>

                </div>

              </div>
            </div>
          </div>
          <div v-else class="card p-5 card-item text-center">
            <span>
              Партнеры не найдены
            </span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import AlertError from '@/components/alert-error.vue';
  import AlertSuccess from "@/components/alert-succes.vue";
  import UserDataService from "@/services/UserDataService";
  import networksDataService from "@/services/NetworksDataService";
  import PartnersDataService from "@/services/PartnersDataService";

  import network_icon from "@/assets/svg/network.svg";
  
  export default {
    data() {
      return {
        referralCode: '',
        errorMessage: '',
        successMessage: '',
        network: [],
        partners: [],
      };
    },
    components: {
      AlertSuccess,
      AlertError
    },
    mounted() {

      UserDataService.get()
          .then(response => {
            const user = response.data;

            this.referralCode = user.user.referralCode;
  
          })
          .catch(e => {
            console.log(e);
          });
  
      networksDataService.get()
          .then(response => {
            this.network = response.data;

          })
          .catch(e => {
            console.log(e);
          });
      this.getPartners()
    },
    methods: {
      async getPartners() {
        PartnersDataService.getParents()
            .then((response) => {
              this.partners = response.data
            })
            .catch((error) => {
              console.log(error)
            })
      }
    }
  };
  </script>
  <style scoped>
  </style>